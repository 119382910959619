import { viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'

export default class PopupAccess {
  constructor() {
    if (!domStorage.popup_access) return

    this.DOM = {
      inner: domStorage.popup_access.querySelector('.inner'),
      btn_allow: domStorage.popup_access.querySelector('.inner .Btn:not(.--white)'),
      btn_deny: domStorage.popup_access.querySelector('.inner .Btn.--white')
    }

    // Appear
    this.setAnimation()
  }

  setAnimation() {
    this.tl = gsap.timeline({
      defaults: { ease: 'expo.inOut', duration: 1.5 },
      delay: 1.5,
      onStart: () => {
        domStorage.body.classList.add('--show-access')

        if (viewStorage.viewScroll.scroll) {
          viewStorage.viewScroll.scroll.stop()
        }
      }
    })

    this.tl.fromTo(domStorage.popup_access, {
      opacity: 0
    }, {
      opacity: 1,
      duration: 1
    }, 0)

    this.tl.fromTo(this.DOM.inner, {
      scale: 0.7,
      y: 50,
      opacity: 0
    }, {
      scale: 1,
      y: 0,
      opacity: 1
    }, 0.45)
  }
}

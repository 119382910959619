import {domStorage, viewStorage} from '../_globals'
import gsap from 'gsap'

export default class ENoticeDownload {
  constructor({section, popup}) {
    // DOM
    this.DOM = {section, popup}
    this.DOM.eNoticeProductsDocuments = this.DOM.section.querySelectorAll('.ENoticeDocument')

    this.DOM.closePopupButton = this.DOM.popup.querySelector('.Close')
    this.DOM.titlePopup = this.DOM.popup.querySelector('.container > .title')
    this.DOM.dynamicTextPopup = this.DOM.popup.querySelector('.container > .wswyg--content strong.dynamic')
    this.DOM.inputEmail = this.DOM.popup.querySelector('#e_notice_download-email')
    this.DOM.downloadButton = this.DOM.popup.querySelector('.Form__actions > .Btn')

    if (!this.DOM.eNoticeProductsDocuments && !this.DOM.eNoticeProductsDocuments.length) return

    // Events
    this.closePopup = this.closePopup.bind(this)
    this.onChangeValue = this.onChangeValue.bind(this)
    this.onChangeEmail = this.onChangeEmail.bind(this)

    this.DOM.eNoticeProductsDocuments.forEach(doc => {
      const productTitle = doc.parentNode.parentNode.querySelector('h2')
      const documentTitle = doc.querySelector(':scope > span')
      const documentSelect = doc.querySelector('select')
      const documentButton = doc.querySelector('.Btn')

      documentSelect.addEventListener('change', this.onChangeValue)

      documentButton.addEventListener('click', () => {
        this.openPopup({
          productTitle: productTitle.textContent,
          documentTitle: documentTitle.textContent,
          documentVersion: documentSelect.value,
          downloadFile: documentSelect.dataset.currentFile
        })
      }, false)
    })

    this.DOM.closePopupButton.addEventListener('click', this.closePopup)

    this.DOM.inputEmail.addEventListener('keyup', this.onChangeEmail)
  }

  /**
   * Popup
   */
  openPopup({ productTitle, documentTitle, documentVersion, downloadFile }) {
    // Update popup datas
    this.DOM.titlePopup.textContent = `${documentTitle} - ${documentVersion}`
    this.DOM.dynamicTextPopup.textContent = `${productTitle} - ${documentTitle} (${documentVersion})`

    document.getElementById('e_notice_download-product').value = productTitle
    document.getElementById('e_notice_download-file').value = documentTitle
    document.getElementById('e_notice_download-version').value = documentVersion

    //Download button
    this.DOM.downloadButton.classList.add('--disabled')

    document.addEventListener('eNoticeOnSuccessEvent', function (event){
      const onSuccessState = event.detail;
      if (onSuccessState) {

        const lien = document.createElement('a');
        lien.href = downloadFile;
        lien.setAttribute('download', '');
        lien.setAttribute('target', '_blank');
        document.body.appendChild(lien);
        lien.click();
        document.body.removeChild(lien);
      }
    })

    gsap.fromTo(this.DOM.popup, {
      opacity: 0,
      y: 50,
    }, {
      opacity: 1,
      y: 0,
      onStart: () => {
        // Remove active className
        this.DOM.popup.classList.add('--active')

        // Overflow hidden on the body
        domStorage.body.classList.add('--show-popup')

        // Enable smooth scroll
        viewStorage.viewScroll.scroll.stop()
      },
      onComplete: () => {},
      duration: 0.75,
      ease: 'power3.inOut'
    })
  }

  closePopup(e) {
    e && e.preventDefault()

    const formOutput = this.DOM.popup.querySelector('.Form__output');
    formOutput.innerHTML = '';

    gsap.to(this.DOM.popup, {
      y: -20,
      opacity: 0,
      onStart: () => {},
      onComplete: () => {
        // Remove active className
        this.DOM.popup.classList.remove('--active')

        // Overflow hidden on the body
        domStorage.body.classList.remove('--show-popup')

        // Enable smooth scroll
        viewStorage.viewScroll.scroll.start()
      },
      duration: 0.75,
      ease: 'power3.inOut'
    })
  }

  /**
   * Forms
   */

  onChangeValue(e) {
    const activeOption = e.target.options[e.target.selectedIndex]

    e.target.dataset.currentFile = activeOption.dataset.file
  }

  onChangeEmail(e) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // If the email pattern is good, we enable the button
    if (emailPattern.test(e.currentTarget.value)) {
      this.DOM.downloadButton.classList.remove('--disabled')
    } else {
      !this.DOM.downloadButton.classList.contains('--disabled') && this.DOM.downloadButton.classList.add('--disabled')
    }
  }

  /**
   * Destroy
   */

  destroy() {
    if (!this.DOM.eNoticeProductsDocuments && !this.DOM.eNoticeProductsDocuments.length) return

    this.DOM.eNoticeProductsDocuments.forEach(doc => {
      const documentSelect = doc.querySelector('select')
      const documentButton = doc.querySelector('.Btn')

      documentSelect.removeEventListener('change', this.onChangeValue)

      documentButton.removeEventListener('click', () => {
        this.openPopup({
          productTitle: productTitle.textContent,
          documentTitle: documentTitle.textContent,
          documentVersion: documentSelect.value,
          downloadFile: documentSelect.dataset.currentFile
        })
      }, false)
    })

    this.DOM.closePopupButton.removeEventListener('click', this.closePopup)

    this.DOM.inputEmail.removeEventListener('keyup', this.onChangeEmail)
  }
}

import { viewStorage } from '../_globals'

export default class NewsletterHubspot {
  constructor() {
    if (!viewStorage.current.querySelector('section.Newsletter.--basic')) return

    // DOM
    this.DOM = { section: viewStorage.current.querySelector('section.Newsletter.--basic') }
    this.DOM.newsletterForm = this.DOM.section.querySelector('.NewsletterForm')

    this.currentLanguage = document.documentElement.getAttribute('lang')
    this.formID_fr = 'fda88324-5f42-441f-beb7-7f5f8eebfeba' 
    this.formID_en = 'ef662907-01c7-4f03-8ca7-1899ec0c08a1'
    this.portalID = '143399652'
    this.region = 'eu1'

    this.initHubspot()
  }

  initHubspot() {
    if (!window.hbspt) {
      const script = document.createElement('script')
      script.src = '//js-eu1.hsforms.net/forms/embed/v2.js'
      this.DOM.newsletterForm.appendChild(script)
      script.addEventListener('load', () => this.createHubspotForm())
    } else {
      this.createHubspotForm()
    }
  }

  createHubspotForm() {
    window.hbspt.forms.create({
      formId: this.currentLanguage === 'fr' ? this.formID_fr : this.formID_en,
      portalId: this.portalID,
      region: this.region,
      target: '.NewsletterForm'
    })
  }

  destroy() {}
}

import {isDEVMODE, viewStorage} from '../_globals';
import ENoticeDownload from '../popups/e-notice-download';
import ENoticeLetter from '../popups/e-notice-letter';
import {Renderer} from "@unseenco/taxi";

export default class PageENotice extends Renderer {
  initialVersions = [];

  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded);
      this.onEnter();
    };

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded);
  }

  onEnter() {
    this.DOM = {
      pageHero: viewStorage.current.querySelector('.PageHero'),
      sectionProducts: viewStorage.current.querySelector('.ENoticeProducts'),
      popupDownload: viewStorage.current.querySelector('.Popup.--e-notice-download'),
      letterPopup: viewStorage.current.querySelector('.Popup.--e-notice-letter'),
      ENoticeSelectProduct: viewStorage.current.querySelector('#e_notice_letter-products'),
      ENoticeSelectVersion: viewStorage.current.querySelector('#e_notice_letter-version'),
    };

    if (this.DOM.pageHero) {
      this.letterPopup = new ENoticeLetter({
        section: this.DOM.pageHero,
        popup: this.DOM.letterPopup,
      });
    }

    if (this.DOM.sectionProducts) {
      this.updateProductSelect();
      this.downloadPopup = new ENoticeDownload({
        section: this.DOM.sectionProducts,
        popup: this.DOM.popupDownload,
      });
    }

    this.DOM.ENoticeSelectProduct.addEventListener('change', () => {
      this.updateProductSelect();
    });
  }

  updateProductSelect() {

    let noticeProductsData = JSON.parse(document.querySelector('.ENotice').getAttribute('data-notice-products'));
    let selectedReferenceProduct = this.DOM.ENoticeSelectProduct.value;
    let versions = [];

    for (let i = 0; i < noticeProductsData.length; i++) {
      const product = noticeProductsData[i];
      if (product.title.toLowerCase().replace(/ /g,"_") === selectedReferenceProduct) {
        const noticeDocument = product.documents.find(doc => doc.notice_type === "1");
        if (noticeDocument) {

          noticeDocument.files.forEach(file => {
            versions.push(file.title);
          });
          break;
        }
      }
    }

    this.DOM.ENoticeSelectVersion.innerHTML = '';

    versions.forEach(version => {
      const option = document.createElement('option');
      option.textContent = version;
      option.value = version.toLowerCase();
      this.DOM.ENoticeSelectVersion.appendChild(option);
    });

  }

  onLeaveCompleted() {
    this.letterPopup && this.letterPopup.destroy();
    this.downloadPopup && this.downloadPopup.destroy();
  }
}

import { Renderer } from '@unseenco/taxi';
import { domStorage, isDEVMODE, viewStorage } from '../_globals'

export default class PageAccess extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {}

    this.init()
  }

  init() {
    // Remove header+footer from the DOM
    domStorage.header.remove()
    domStorage.footer.remove()
  }

  onLeaveCompleted() {}
}

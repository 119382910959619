import { viewStorage } from '../_globals'

export default class NewsletterInvestorsHubspot {
  constructor() {
    if (!viewStorage.current.querySelector('section.Newsletter.--investors')) return

    // DOM
    this.DOM = { section: viewStorage.current.querySelector('section.Newsletter.--investors') }
    this.DOM.newsletterForm = this.DOM.section.querySelector('.NewsletterForm.--investors')

    this.currentLanguage = document.documentElement.getAttribute('lang')
    this.formID_fr = 'd9916ad2-d3ba-4b91-9fd8-8ebb38d25668' 
    this.formID_en = '5979b082-0009-4355-bfb4-72333cecc060'
    this.portalID = '143399652'
    this.region = 'eu1'

    this.initHubspot()
  }

  initHubspot() {
    if (!window.hbspt) {
      const script = document.createElement('script')
      script.src = '//js-eu1.hsforms.net/forms/embed/v2.js'
      this.DOM.newsletterForm.appendChild(script)
      script.addEventListener('load', () => this.createHubspotForm())
    } else {
      this.createHubspotForm()
    }
  }

  createHubspotForm() {
    window.hbspt.forms.create({
      formId: this.currentLanguage === 'fr' ? this.formID_fr : this.formID_en,
      portalId: this.portalID,
      region: this.region,
      target: '.NewsletterForm.--investors'
    })
  }

  destroy() {}
}

import { domStorage, isDEVMODE, viewStorage } from '../_globals'
import gsap from 'gsap'

export default class ENoticeLetter {
  constructor({ section, popup }) {
    // DOM
    this.DOM = { section, popup }
    this.DOM.openPopupButton = this.DOM.section.querySelector('.Btn')

    this.DOM.closePopupButton = this.DOM.popup.querySelector('.Close')

    if (!this.DOM.openPopupButton) return

    // Events
    this.openPopup = this.openPopup.bind(this)
    this.closePopup = this.closePopup.bind(this)

    this.DOM.openPopupButton.addEventListener('click', this.openPopup)
    this.DOM.closePopupButton.addEventListener('click', this.closePopup)
  }

  /**
   * Popup
   */

  openPopup(){
    gsap.fromTo(this.DOM.popup, {
      opacity: 0,
      y: 50,
    }, {
      opacity: 1,
      y: 0,
      onStart: () => {
        // Remove active className
        this.DOM.popup.classList.add('--active')

        // Overflow hidden on the body
        domStorage.body.classList.add('--show-popup')

        // Enable smooth scroll
        viewStorage.viewScroll.scroll.stop()
      },
      onComplete: () => {},
      duration: 0.75,
      ease: 'power3.inOut'
    })
  }

  closePopup(e){
    e && e.preventDefault()

    const formOutput = this.DOM.popup.querySelector('.Form__output');
    formOutput.innerHTML = '';

    gsap.to(this.DOM.popup, {
      y: -20,
      opacity: 0,
      onStart: () => {},
      onComplete: () => {
        // Remove active className
        this.DOM.popup.classList.remove('--active')

        // Overflow hidden on the body
        domStorage.body.classList.remove('--show-popup')

        // Enable smooth scroll
        viewStorage.viewScroll.scroll.start()
      },
      duration: 0.75,
      ease: 'power3.inOut'
    })
  }

  /**
   * Destroy
   */

  destroy() {
    if (!this.DOM.openPopupButton) return

    this.DOM.openPopupButton.removeEventListener('click', this.openPopup)
  }
}
